import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  mode: 'tds-mode-light' | 'tds-mode-dark' = 'tds-mode-light';
  modeVariant: 'tds-mode-variant-primary' | 'tds-mode-variant-secondary' = 'tds-mode-variant-secondary';
  languageCode = signal<string>('');

  modeSignal = signal(this.mode);
  modeVariantSignal = signal(this.modeVariant);

  changeUIMode(darkModeOn: boolean) {
    if (darkModeOn) {
      this.modeSignal.set('tds-mode-dark');
      return;
    } else {
      this.modeSignal.set('tds-mode-light');
      return;
    }
  }

  changeModeVariant(primaryMode: boolean) {
    if (primaryMode) {
      this.modeVariantSignal.set('tds-mode-variant-primary');
      return;
    } else {
      this.modeVariantSignal.set('tds-mode-variant-secondary');
      return;
    }
  }
}
